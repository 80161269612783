import oops from "~/components/assets/oops.png";
import full_logo from "~/components/assets/full_logo.png";
import { Copy } from "./icons";
import { useEffect, useState } from "react";
import { isRouteErrorResponse } from "@remix-run/react";

type ErrorPageProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  code?: string;
};

export const ErrorPage = (props: ErrorPageProps) => {
  const [copyStatus, setCopyStatus] = useState("");
  const [code, setCode] = useState("");
  const { error } = props;

  const onClick = async (code: string) => {
    navigator.clipboard.writeText(code);
    setCopyStatus("Copied!");
    await new Promise((r) => setTimeout(r, 1000)).then(() => setCopyStatus(""));
  };

  useEffect(() => {
    if (props.code) {
      setCode(props.code);
    }
  }, [props.code]);

  // TODO: Change this link in production
  const link = `https://loanx.sentry.io/issues/6075976486/events/${code}/`;

  if (!code) return null;

  return (
    <div
      style={{
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        fontFamily: "Inter",
      }}
    >
      <img src={full_logo} alt="Logo" style={{ margin: "auto" }} />
      <img style={{ margin: "auto" }} src={oops} alt="Oops" />
      <h1>An unexpected error occurred</h1>
      {isRouteErrorResponse(error) ? (
        <span>
          {error.status} {error.statusText || error.data}
        </span>
      ) : null}
      {error instanceof Error ? <span>{error.message}</span> : null}
      <div
        style={{
          margin: "auto",
          display: "flex",
          padding: "1rem",
          alignItems: "center",
        }}
      >
        <div style={{ paddingRight: "0.5rem" }}>Error code:</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <a href={link} target="_blank" rel="noreferrer">
            <span
              style={{
                backgroundColor: "#EFF6FF",
                color: "#3B82F6",
                padding: "0.5rem",
                borderRadius: 5,
                fontFamily: "Space Mono",
              }}
            >
              {code}
            </span>
          </a>
          <Copy style={{ marginLeft: "0.5rem", cursor: "pointer" }} onClick={() => onClick(code)} role="button" />
        </div>
      </div>
      <div>{copyStatus}</div>
    </div>
  );
};
